import React from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function page(Component) {
    return class extends React.Component {
        render() {
            return <div className="d-flex flex-column h-100">
                <Navbar location={this.props.location.pathname}
                        title={this.props.title} previous={this.props.previous} next={this.props.next}
                        onShowModal={() => this.showModal && this.showModal()}/>
                <Component {...this.props} setShowModal={f => this.showModal = f}/>
                <Footer/>
            </div>;
        }
    }
}
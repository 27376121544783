export const roundScore = (roundNumber, bet, won, bonus) => {
    if (bet === '' || won === '') {
        return 0;
    }
    bet = +bet;
    won = +won;
    bonus = +bonus;
    if (bet === 0) {
        if (won === bet) {
            return roundNumber * 10 + bonus;
        } else {
            return roundNumber * -10;
        }
    } else {
        if (won === bet) {
            return bet * 20 + bonus;
        } else {
            let difference = Math.abs(won - bet);
            return difference * -10;
        }
    }
};
export const PLAYER_NAME = 'PLAYER_NAME';
export const PLAYER_PLAYS = 'PLAYER_PLAYS';
export const PLAYER_CLEAR = 'PLAYER_CLEAR';
export const ROUND_BET = 'ROUND_BET';
export const ROUND_WON = 'ROUND_WON';
export const ROUND_BONUS = 'ROUND_BONUS';
export const ROUND_CLEAR = 'ROUND_CLEAR';

export function playerName(payload) {
    return {
        type: PLAYER_NAME,
        payload
    }
}

export function playerPlays(payload) {
    return {
        type: PLAYER_PLAYS,
        payload
    }
}

export function playerClear(payload) {
    return {
        type: PLAYER_CLEAR,
        payload
    }
}

export function roundBet(payload) {
    return {
        type: ROUND_BET,
        payload
    }
}

export function roundWon(payload) {
    return {
        type: ROUND_WON,
        payload
    }
}

export function roundBonus(payload) {
    return {
        type: ROUND_BONUS,
        payload
    }
}

export function roundClear(payload) {
    return {
        type: ROUND_CLEAR,
        payload
    }
}
import React from 'react';
import { connect } from 'react-redux';

import page from '../util/page';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';

import { roundBet, roundWon, roundBonus, roundClear } from '../actions';
import { roundScore } from '../util/score';


const mapStateToProps = (state, ownProps) => {
    return {
        players: state.players,
        round: state.rounds[ownProps.match.params.roundNumber - 1]
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    bet: payload => dispatch(roundBet({
        ...payload,
        round: ownProps.match.params.roundNumber - 1
    })),
    won: payload => dispatch(roundWon({
        ...payload,
        round: ownProps.match.params.roundNumber - 1
    })),
    bonus: payload => dispatch(roundBonus({
        ...payload,
        round: ownProps.match.params.roundNumber - 1
    })),
    clear: payload => dispatch(roundClear({
        ...payload,
        round: ownProps.match.params.roundNumber - 1
    }))
});

class Round extends React.Component {

    constructor() {
        super();

        this.state = {
            showModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidMount() {
        this.props.setShowModal(this.showModal);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const attribute = target.dataset.attribute;
        const player = +target.dataset.index;

        this.props[attribute]({
            player,
            value
        })
    }

    showModal() {
        this.setState({
            showModal: true
        });
    }

    closeModal() {
        this.setState({
            showModal: false
        });
    }

    handleRemove() {
        this.props.clear();
        this.closeModal();
    }

    render() {
        const round = this.props.match.params.roundNumber;
        let rows = [];
        let betOptions = [<option key={0} value=""></option>];
        for (let i = 0; i <= round; i++) {
            betOptions.push(
                <option key={i+1}>{i}</option>
            );
        }
        this.props.players.filter(player => player.name !== '').forEach((player, index) => {
            const { bet, won, bonus } = this.props.round[index];
            const score = roundScore(round, bet, won, bonus);
            rows.push(
                <Form.Group as={Form.Row} key={index}>
                    <Form.Label column>{player.name}</Form.Label>
                    <Col>
                        <Form.Control as="select" name={`round-${round}-player-${index}-bet`}
                                      data-index={index} data-attribute="bet"
                                      value={bet} onChange={this.handleChange}>
                            {betOptions}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Control as="select" name={`round-${round}-player-${index}-won`}
                                      data-index={index} data-attribute="won"
                                      value={won} onChange={this.handleChange}>
                            {betOptions}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Control as="select" name={`round-${round}-player-${index}-bonus`}
                                      data-index={index} data-attribute="bonus"
                                      value={bonus} onChange={this.handleChange}>
                            <option value="0">-</option>
                            <option value="30">1P</option>
                            <option value="60">2P</option>
                            <option value="90">3P</option>
                            <option value="120">4P</option>
                            <option value="150">5P</option>
                            <option value="180">6P</option>
                            <option value="50">SK</option>
                            <option value="80">1P+SK</option>
                            <option value="110">2P+SK</option>
                            <option value="140">3P+SK</option>
                            <option value="170">4P+SK</option>
                            <option value="200">5P+SK</option>
                            <option value="230">6P+SK</option>
                        </Form.Control>
                    </Col>
                    <Col>
                        <Form.Control plaintext readOnly value={score} />
                    </Col>
                </Form.Group>
            );
        });
        return (
            <Container as="main">
                <Form>
                    <Form.Row>
                        <Form.Label column>Player</Form.Label>
                        <Form.Label column>Bet</Form.Label>
                        <Form.Label column>Won</Form.Label>
                        <Form.Label column>Bonus</Form.Label>
                        <Form.Label column>Score</Form.Label>
                    </Form.Row>
                    {rows}
                </Form>
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to remove this round?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                            No
                        </Button>
                        <Button variant="danger" onClick={this.handleRemove}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(page(Round));
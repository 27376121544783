import { PLAYER_NAME, PLAYER_CLEAR, ROUND_BET, ROUND_WON, ROUND_BONUS, ROUND_CLEAR } from '../actions';
import { MAX_PLAYERS, MAX_ROUNDS } from '../config';

const initialState = createInitialState();

function createInitialState() {
    let state = {
        players: [],
        rounds: []
    };

    for (let i = 0; i < MAX_ROUNDS; i++) {
        state.rounds[i] = [];
    }

    for (let i = 0; i < MAX_PLAYERS; i++) {
        state.players[i] = {
            name:   '',
            score:  0
        };
        for (let j = 0; j < MAX_ROUNDS; j++) {
            state.rounds[j][i] = {
                bet:    '',
                won:    '',
                bonus:  '',
                score:  0
            }
        }
    }

    return state;
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case PLAYER_NAME:
            return {
                ...state,
                players: state.players.map((item, index) => {
                    if (index !== action.payload.player) {
                        return item;
                    }
                    return {
                        ...item,
                        name: action.payload.value
                    }
                })
            };
        case PLAYER_CLEAR:
            return {
                ...state,
                players: state.players.map((item) => {
                    return {
                        ...item,
                        name:   '',
                        score:  0,
                        plays:  false
                    }
                }),
                rounds: state.rounds.map((item) => {
                    return item.map((item) => {
                        return {
                            ...item,
                            bet: '',
                            won: '',
                            bonus: ''
                        }
                    });
                })
            };
        case ROUND_BET: {
            return {
                ...state,
                rounds: state.rounds.map((item, index) => {
                    if (index !== action.payload.round) {
                        return item;
                    }
                    return item.map((item, index) => {
                        if (index !== action.payload.player) {
                            return item;
                        }
                        return {
                            ...item,
                            bet: action.payload.value
                        }
                    });
                })
            };
        }
        case ROUND_WON: {
            return {
                ...state,
                rounds: state.rounds.map((item, index) => {
                    if (index !== action.payload.round) {
                        return item;
                    }
                    return item.map((item, index) => {
                        if (index !== action.payload.player) {
                            return item;
                        }
                        return {
                            ...item,
                            won: action.payload.value
                        }
                    });
                })
            };
        }
        case ROUND_BONUS: {
            return {
                ...state,
                rounds: state.rounds.map((item, index) => {
                    if (index !== action.payload.round) {
                        return item;
                    }
                    return item.map((item, index) => {
                        if (index !== action.payload.player) {
                            return item;
                        }
                        return {
                            ...item,
                            bonus: action.payload.value
                        }
                    });
                })
            };
        }
        case ROUND_CLEAR: {
            return {
                ...state,
                rounds: state.rounds.map((item, index) => {
                    if (action.payload.round !== undefined && index !== action.payload.round) {
                        return item;
                    }
                    return item.map((item) => {
                        return {
                            ...item,
                            bet: '',
                            won: '',
                            bonus: ''
                        }
                    });
                })
            };
        }
        default:
            break;
    }
    return state;
}

export default rootReducer;
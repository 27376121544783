import React from 'react';
import {connect} from 'react-redux';

import page from '../util/page';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { MAX_PLAYERS } from "../config";
import {playerName, playerClear} from "../actions";

const mapStateToProps = state => {
    return {
        players: state.players
    };
};

const mapDispatchToProps = dispatch => ({
    name: payload => dispatch(playerName(payload)),
    clear: payload => dispatch(playerClear({...payload}))
});

class Players extends React.Component {

    constructor () {
        super();

        this.state = {
            showModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidMount() {
        this.props.setShowModal(this.showModal);
    }

    handleChange (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const attribute = target.dataset.attribute;
        const player = +target.dataset.index;

        this.props[attribute]({
            player,
            value
        })
    }

    showModal() {
        this.setState({
            showModal: true
        });
    }

    closeModal() {
        this.setState({
            showModal: false
        });
    }

    handleRemove() {
        this.props.clear();
        this.closeModal();
    }

    render () {
        let formGroups = [];
        for (let i = 0; i < MAX_PLAYERS; i++) {
            formGroups.push(
                <Form.Group key={i} controlId={`player-${i + 1}`}>
                    <Form.Control type="text" placeholder={`Player ${i + 1}`} name={`player-${i + 1}-name`}
                                  data-index={i} data-attribute="name"
                                  value={this.props.players[i].name}
                                  isValid={this.props.players[i].name !== ''}
                                  onChange={this.handleChange} />
                </Form.Group>
            );
        }
        return (
            <Container as="main">
                <Form>
                    {formGroups}
                </Form>
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to remove all the data?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                            No
                        </Button>
                        <Button variant="danger" onClick={this.handleRemove}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(page(Players));
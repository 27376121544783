import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer mt-auto py-3">
                <Container>
                    <p>Antoni Oliver.</p>
                </Container>
            </footer>
        );
    }
};
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import page from '../util/page';

import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import {MAX_ROUNDS} from "../config";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';

import { roundScore } from '../util/score';

import { roundClear } from '../actions';

const mapStateToProps = state => {
    return {
        players: state.players,
        rounds: state.rounds
    };
};

const mapDispatchToProps = dispatch => ({
    clear: payload => dispatch(roundClear({...payload}))
});

class Summary extends React.Component {
    constructor() {
        super();

        this.state = {
            showModal: false
        };

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidMount() {
        this.props.setShowModal(this.showModal);
    }

    showModal() {
        this.setState({
            showModal: true
        });
    }

    closeModal() {
        this.setState({
            showModal: false
        });
    }

    handleRemove() {
        this.props.clear();
        this.closeModal();
    }

    render() {
        let ths = [];
        for (let i = 1; i <= MAX_ROUNDS; i++) {
            ths.push(<th key={i}><Link to={`/round/${i}`}>{i}</Link></th>);
        }

        let rows = [];
        this.props.players.filter(player => player.name !== '').forEach((player, index) => {
            let tds = [];
            let total = 0;
            this.props.rounds.forEach((round, rIndex) => {
                const { bet, won, bonus } = round[index];
                const score = roundScore(rIndex + 1, bet, won, bonus);
                total += score;
                tds.push(<td key={rIndex}>{score}</td>);
            });
            rows.push(
                <tr key={index}>
                    <td>{player.name}</td>
                    {tds}
                    <td>{total}</td>
                </tr>
            );
        });

        return (
            <Container as="main">
                <Table striped bordered responsive>
                    <thead>
                    <tr>
                        <th><Link to="/players">Player</Link></th>
                        {ths}
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </Table>
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to remove all the rounds?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>
                            No
                        </Button>
                        <Button variant="danger" onClick={this.handleRemove}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(page(Summary));
import React, { Component } from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {MAX_ROUNDS} from "../config";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';

class MyNavbar extends Component {

    render() {

        let navLinks = [];
        let eventKey = 0;

        navLinks.push(<NavDropdown.Item as={Link} key={eventKey} eventKey={++eventKey} to="/players" href="/players" active={this.props.location === '/players'}>Players</NavDropdown.Item>);
        navLinks.push(<NavDropdown.Divider key={'a'}/>);

        for (let i = 1; i <= MAX_ROUNDS; i++) {
            navLinks.push(<NavDropdown.Item as={Link} key={eventKey} eventKey={++eventKey} to={`/round/${i}`} href={`/round/${i}`} active={this.props.location === `/round/${i}`}>{`Round ${i}`}</NavDropdown.Item>)
        }
        navLinks.push(<NavDropdown.Divider key={'b'}/>);
        navLinks.push(<NavDropdown.Item as={Link} key={eventKey} eventKey={++eventKey} to="/summary" href="/summary" active={this.props.location === '/summary'}>Summary</NavDropdown.Item>);

        return (
            <Navbar bg="dark" variant="dark" sticky="top">
                <Container>
                    <Nav className="mr-auto">

                        <NavDropdown title={this.props.title} active>
                            {navLinks}
                        </NavDropdown>
                    </Nav>
                <Nav as={ButtonGroup} className="mr-2">
                    <Nav.Item as={Link} className="btn btn-primary" to="/players" href="/players"><i className="oi oi-person"/></Nav.Item>
                    <Nav.Item as={Link} className={`btn btn-primary ${this.props.previous === undefined ? 'disabled' : ''}`} to={this.props.previous || '#'} href={this.props.previous}><i className="oi oi-caret-left"/></Nav.Item>
                    <Nav.Item as={Link} className={`btn btn-primary ${this.props.next === undefined ? 'disabled' : ''}`} to={this.props.next || '#'} href={this.props.next}><i className="oi oi-caret-right"/></Nav.Item>
                    <Nav.Item as={Link} className="btn btn-primary" to="/summary" href="/summary"><i className="oi oi-list"/></Nav.Item>
                </Nav>
                <Nav as={ButtonGroup}>
                    <Nav.Item as={Button} variant="danger" onClick={this.props.onShowModal}><i className="oi oi-trash"/></Nav.Item>
                </Nav>
                {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="main-navbar">
                    <Nav className="mr-auto">
                        {navLinks}
                    </Nav>
                </Navbar.Collapse>*/}
                </Container>
            </Navbar>
        );
    }
}

export default MyNavbar;
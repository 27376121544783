import { createStore } from 'redux';
import rootReducer from '../reducers/index';
import { loadState, saveState } from '../util/storage';
import throttle from 'lodash/throttle';

const store = createStore(rootReducer, loadState());

store.subscribe(throttle(() => {
    saveState(store.getState());
}, 1000));

export default store;
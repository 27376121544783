import React from 'react';
import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'open-iconic/font/css/open-iconic-bootstrap.min.css';

import Players from './components/Players';
import Round from './components/Round';
import Summary from './components/Summary';

import { MAX_ROUNDS } from './config';

class App extends React.Component {
    render() {
        return (
            <Router>
                <Route exact path="/" render={() =>
                    <Redirect to="/players"/>
                }/>
                <Route path="/players" render={props =>
                    <Players {...props} title="Players" next="/round/1"/>
                } />
                <Route path="/round/:roundNumber" render={props =>
                    <Round {...props} title={`Round ${props.match.params.roundNumber}`}
                           previous={+props.match.params.roundNumber === 1 ? '/players' : `/round/${+props.match.params.roundNumber - 1}`}
                           next={+props.match.params.roundNumber === MAX_ROUNDS ? '/summary' : `/round/${+props.match.params.roundNumber + 1}`}
                    />
                } />
                <Route path="/summary" render={props =>
                    <Summary {...props} title="Summary" previous={`/round/${MAX_ROUNDS}`} />
                } />
            </Router>
        );
    }
}

export default App;
